import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router, } from "react-router-dom";
//import { withRouter } from "react-router";
//import { withLocation } from "react-router";

import store from "./store";
import { Provider } from "react-redux";



import { icons } from './assets/icons'

import './i18n';

React.icons = icons


ReactDOM.render(
  <React.StrictMode>

<Provider store={store}>
        <Router>

    <App />

    </Router>
      </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);


/*
ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
*/


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
