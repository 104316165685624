export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FILTER_PRODUCTS_BY_SIZE = "FILTER_PRODUCTS_BY_SIZE";
export const ORDER_PRODUCTS_BY_PRICE = "ORDER_PRODUCTS_BY_PRICE";
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_BLONJO = "ADD_TO_BLONJO";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CREATE_ORDER = "CREATE_ORDER";
export const CLEAR_ORDER = "CLEAR_ORDER";
export const CLEAR_CART = "CLEAR_CART";
export const FETCH_ORDERS = "FETCH_ORDERS";
export const CART_IMAGES = "CART_IMAGES";
export const CART_DETAIL = "CART_DETAIL";
export const CART_MODAL = "CART_MODAL";
export const CART_TROLI = "CART_TROLI";
export const RELOAD_PRODUCT = "RELOAD_PRODUCT";
export const RELOAD_PRODUCT_MORE = "RELOAD_PRODUCT_MORE";
export const UPDATE_PATH = "UPDATE_PATH";
export const UPDATE_WAITING = "UPDATE_WAITING";
export const INFO_CUSTOMER = "INFO_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER2 = "UPDATE_CUSTOMER2";
export const ISI_LIST_PRODUCT = "ISI_LIST_PRODUCT";
export const THE_ROOT = "THE_ROOT";
//export const INI_ADMIN = "INI_ADMIN";
