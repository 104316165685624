import {
  UPDATE_WAITING,
  UPDATE_PATH,
  INFO_CUSTOMER,
  ADD_TO_BLONJO,
  REMOVE_FROM_CART,
  CART_IMAGES,
  CART_DETAIL,
  CART_MODAL,
  CART_TROLI,
  RELOAD_PRODUCT,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER2,
  
  RELOAD_PRODUCT_MORE,
  ISI_LIST_PRODUCT,THE_ROOT
} from "../types";

import axios from "axios";
import Koneksiku from "../Services/Koneksiku";
//import { ViewArrayRounded } from "@material-ui/icons";

const Urlku = Koneksiku.getUrl();
const axiosConfig2 = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("bigStore2.jwt"),
    "Content-Type": "application/json;charset=UTF-8;multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
};

export const fetchDataProductMore = (nilai) => (dispatch, getState) => {
  let bento = getState().generalState.product;
  console.log("kusing4", bento.length);

  for (const key of Object.keys(nilai.bdata)) {
    bento.push(nilai.bdata[key]);
  }

  let warna1 = getState().generalState.warna1;
  for (const key of Object.keys(nilai.warna1)) {
    warna1.push(nilai.warna1[key]);
  }

  let size1 = getState().generalState.size1;
  for (const key of Object.keys(nilai.size)) {
    size1.push(nilai.size[key]);
  }

  let image1 = getState().generalState.image1;
  for (const key of Object.keys(nilai.image1)) {
    image1.push(nilai.image1[key]);
  }

  let data_harga = getState().generalState.data_harga;
  for (const key of Object.keys(nilai.data_harga)) {
    data_harga.push(nilai.data_harga[key]);
  }

  let data_diskon = getState().generalState.data_diskon;
  for (const key of Object.keys(nilai.data_diskon)) {
    data_diskon.push(nilai.data_diskon[key]);
  }

  let nilai1 = {
    warna1: warna1,
    produk: bento,
    size1: size1,
    image1: image1,
    data_harga: data_harga,
    data_diskon: data_diskon,
  };
  dispatch({
    type: RELOAD_PRODUCT_MORE,
    payload: nilai1,
  });

  dispatch({
    type: UPDATE_WAITING,
    payload: false,
  });
};

export const isiListProduct = (nilai) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_WAITING,
    payload: true,
  });
  //let data_lama = getState().generalState.product;
  let filter2 = nilai.trim().toUpperCase();

  //alert(filter2);
  let xx = [];
  if (filter2 !== "") {
    //this.props.generalState.product
    xx = getState().generalState.product.filter(function (el) {
      return (
        el.kd1.toUpperCase().indexOf(filter2) >= 0 ||
        el.ket1.toUpperCase().indexOf(filter2) >= 0
      );
    });
  } else {
    xx = getState().generalState.product;
  }
  dispatch({
    type: ISI_LIST_PRODUCT,
    payload: xx,
  });

  dispatch({
    type: UPDATE_WAITING,
    payload: false,
  });
};

export const fetchDataProduct2 = (nilai) => (dispatch, getState) => {
  dispatch({
    type: RELOAD_PRODUCT,
    payload: nilai,
  });

  dispatch({
    type: UPDATE_WAITING,
    payload: false,
  });
};

//THE_ROOT:
  //    return { ...state, [action.payload.nama]: action.payload.nilai };


export const theRoot = (nilai) => (dispatch, getState) => {
    dispatch({
      type: THE_ROOT,
      payload: nilai,
    });
  };

  export const theRoot2 = (nilai) => dispatch => {
    dispatch({
      type: THE_ROOT,
      payload: nilai,
    });
    return Promise.resolve();
  };

  

export const updateCustomer = (nilai) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_CUSTOMER,
    payload: nilai,
  });
};

export const updateCustomer2 = (nilai) => (dispatch, getState) => {
  var keyNames = Object.keys(nilai);
  //let cartItems = getState().generalState.cartModal.slice();
  let cartItems = getState().generalState.data_customer2;
  var keyMaster = Object.keys(cartItems);

  let ada = 0;
  for (const key of Object.keys(keyNames)) {
    let x = keyNames[key];
    ada = 0;
    for (const ke2 of Object.keys(keyMaster)) {
      let x2 = keyMaster[ke2];
      if (x2.trim() === x.trim()) {
        ada = 1;
        break;
      }
    }
    if (ada === 1) {
      cartItems[x] = nilai[x];
    }
  }

  dispatch({
    type: UPDATE_CUSTOMER2,
    payload: cartItems,
  });
};

export const ubahCartImg = (nilai) => (dispatch, getState) => {
  dispatch({
    type: CART_IMAGES,
    payload: nilai,
  });
};

export const ubahInfoCustomer = (nilai) => (dispatch, getState) => {
  dispatch({
    type: INFO_CUSTOMER,
    payload: nilai,
  });
};

export const ubahCartDtl = (nilai) => (dispatch, getState) => {
  dispatch({
    type: CART_DETAIL,
    payload: nilai,
  });
};

export const detectPath = (nilai) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_PATH,
    payload: nilai,
  });
};

export const updateWaiting = (nilai) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_WAITING,
    payload: nilai,
  });
};

export const ubahCartModal = (nilai) => (dispatch, getState) => {
  var keyNames = Object.keys(nilai);
  //let cartItems = getState().generalState.cartModal.slice();
  let cartItems = getState().generalState.cartModal;
  var keyMaster = Object.keys(cartItems);

  let ada = 0;
  for (const key of Object.keys(keyNames)) {
    let x = keyNames[key];
    ada = 0;
    for (const ke2 of Object.keys(keyMaster)) {
      let x2 = keyMaster[ke2];
      if (x2.trim() === x.trim()) {
        ada = 1;
        break;
      }
    }
    if (ada === 1) {
      cartItems[x] = nilai[x];
    }
  }

  dispatch({
    type: CART_MODAL,
    payload: cartItems,
  });
  //localStorage.setItem("keranjangBlonjo", JSON.stringify(cartItems3));
};

export const troliBelanja = (nilai) => (dispatch, getState) => {
  dispatch({
    type: CART_TROLI,
    payload: nilai,
  });
};

export const ubahNilai = (nilai) => (dispatch, getState) => {
  //const nl1 = getState().generalState.generalItems;
  /*
  dispatch({
    type: ADD_TO_CART,
    payload: {
      generalState: nilai,
    },
  });
  localStorage.setItem("generalItems", JSON.stringify(nilai));
  */
};

export const periksaBelanjaan = () => (dispatch, getState) => {
  var cartItems3 = [];
  //console.log("nani", cartItems3.length);
  var elor = 0;

  const aksi2 = (res) => {
    let te = JSON.stringify(res);
    let te2 = JSON.parse(te);

    let data2 = [];
    if (te2.data.hasil === 1) {
      //console.log("nana",te2.data.dita)
      //console.log("nana",te2.data.dita.length)
      if (te2.data.dita.length > 0) {
        data2 = JSON.parse(te2.data.dita[0].info);
      } else {
        elor = 1;
      }
    } else {
      elor = 1;
    }
    let gros = 0;
    let disc = 0;
    let ppn = 0;
    let totalBelanja = 0;

    if (elor === 0) {
      for (const key of Object.keys(data2)) {
        var dt = data2[key];

        gros = 0;
        disc = 0;
        ppn = 0;
        if (dt.qty !== undefined && dt.price !== undefined) {
          gros = dt.qty * dt.price;
        }
        if (dt.persen_disc !== undefined) {
          disc = (gros * dt.persen_disc) / 100;
        }
        if (gros !== 0) {
          ppn = ((gros - disc) * 10) / 100;
        }
        totalBelanja = totalBelanja + (gros - disc + ppn);

        if (dt.kd1 !== undefined) {
          cartItems3.push(dt);
        }
      }
    }

    var dt1 = {
      tas: cartItems3,
      total: totalBelanja,
    };

    //console.log("nina", dt1);
    return dt1;
  };

  const aksi1 = async () => {
    console.log('dita',Urlku + "/ambil_data_belanjaan")
    const res = await axios.get(Urlku + "/ambil_data_belanjaan", axiosConfig2);
    //console.log("nina3", res);
    const hsl1 = await aksi2(res);

    if (hsl1.tas.length !== 0) {
      //===========================================================================
      dispatch({
        type: ADD_TO_BLONJO,
        payload: {
          keranjangState: hsl1.tas,
        },
      });

      let cartItems5 = getState().generalState.cartModal;
      cartItems5.totalBelanja = hsl1.total;

      dispatch({
        type: CART_MODAL,
        payload: cartItems5,
      });
    }
  };

  aksi1();
};

export const ubahBlonjo = (nilai) => (dispatch, getState) => {
  let cartItems3 = [];
  let elor = 0;
  let masukin = 0;

  const aksi2 = (res) => {
    let te = JSON.stringify(res);
    let te2 = JSON.parse(te);

    let data2 = [];
    if (te2.data.hasil === 1) {
      if (te2.data.dita.length > 0) {
        data2 = JSON.parse(te2.data.dita[0].info);
      } else {
        elor = 1;
      }
    } else {
      elor = 1;
    }
    let gros = 0;
    let disc = 0;
    let ppn = 0;
    let totalBelanja = 0;
    let ada = 0;
    if (elor === 1) {
      //alert("masuk1");
      masukin = 1;
      cartItems3.push(nilai);
    } else {
      for (const key of Object.keys(data2)) {
        var dt = data2[key];

        gros = 0;
        disc = 0;
        ppn = 0;

        if (dt.kd1 !== undefined) {
          if (dt.kd1.trim() === nilai.kd1.trim()) {
            masukin = 1;
            cartItems3.push(nilai);
            ada = 1;
            gros = nilai.qty * nilai.price;
            disc = (gros * nilai.persen_disc) / 100;
          } else {
            gros = dt.qty * dt.price;
            disc = (gros * dt.persen_disc) / 100;
            cartItems3.push(dt);
          }
        }
        if (gros !== 0) {
          ppn = ((gros - disc) * 10) / 100;
        }
        totalBelanja = totalBelanja + (gros - disc + ppn);
      }

      //console.log("cinta1",ada)
    }

    if (ada === 0) {
      gros = 0;
      disc = 0;
      ppn = 0;
      if (nilai.qty !== undefined && nilai.price !== undefined) {
        gros = nilai.qty * nilai.price;
      }
      if (nilai.persen_disc !== undefined) {
        disc = (gros * nilai.persen_disc) / 100;
      }
      if (gros !== 0) {
        ppn = ((gros - disc) * 10) / 100;
      }
      totalBelanja = totalBelanja + (gros - disc + ppn);

      //cartItems3.push(nilai);
    }
    if (masukin === 0) {
      masukin = 1;
      cartItems3.push(nilai);
    }

    var dt1 = {
      tas: cartItems3,
      total: totalBelanja,
    };

    //console.log("cinta5", dt1);
    return dt1;
  };

  const aksi3 = (hasil) => {
    //console.log("nina2", hasil);

    var json_arr = JSON.stringify(hasil);
    const formData = new FormData();
    formData.append("data2", json_arr);
    formData.append("banyak", hasil.length);
    return axios
      .post(
        Urlku + "/simpan-data-belanja",
        formData,
        Koneksiku.getAxiosConfig()
      )
      .then((res) => {
        let te = JSON.stringify(res);
        let te2 = JSON.parse(te);
        if (te2.data.hasil === 1) {
          return 1;
        } else {
          return 0;
        }
      })
      .catch(function (error) {
        return 0;
      });
  };

  const aksi1 = async () => {
    const res = await axios.get(Urlku + "/ambil_data_belanjaan", axiosConfig2);
    //console.log("nina3", res);
    const hsl1 = await aksi2(res);

    const simpanDiAwan = await aksi3(hsl1.tas);

    if (simpanDiAwan === 1) {
      //===========================================================================
      dispatch({
        type: ADD_TO_BLONJO,
        payload: {
          keranjangState: hsl1.tas,
        },
      });

      let cartItems5 = getState().generalState.cartModal;
      cartItems5.totalBelanja = hsl1.total;

      console.log("cinta2", hsl1.total);

      dispatch({
        type: CART_MODAL,
        payload: cartItems5,
      });
    }
  };

  aksi1();
};

export const ubahBlonjo2 = (nilai) => (dispatch, getState) => {
  let totalBelanja = 0;

  const cartItems2 = getState().generalState.keranjangBlonjo;
  let cartItems3 = [];

  let gros = nilai.qty * nilai.price;
  let disc = (gros * nilai.persen_disc) / 100;
  let ppn = ((gros - disc) * 10) / 100;
  totalBelanja = gros - disc + ppn;

  if (cartItems2 === undefined) {
    cartItems3.push(nilai);
  } else {
    cartItems3 = getState().generalState.keranjangBlonjo.slice();
    let urutan = 0;
    for (const key of Object.keys(cartItems3)) {
      let dt = cartItems3[key];

      gros = dt.qty * dt.price;
      disc = (gros * dt.persen_disc) / 100;
      ppn = ((gros - disc) * 10) / 100;
      totalBelanja = totalBelanja + (gros - disc + ppn);

      if (dt.kd1.trim() === nilai.kd1.trim()) {
        urutan = key;
        break;
      }
    }

    if (urutan !== 0) {
      cartItems3[urutan] = nilai;
    } else {
      cartItems3.push(nilai);
    }
    //bento
  }

  //console.log("nana1",cartItems3);

  dispatch({
    type: ADD_TO_BLONJO,
    payload: {
      keranjangState: cartItems3,
    },
  });

  let cartItems5 = getState().generalState.cartModal;
  cartItems5.totalBelanja = totalBelanja;

  dispatch({
    type: CART_MODAL,
    payload: cartItems5,
  });

  //console.log("nana2",cartItems5);

  localStorage.setItem("keranjangBlonjo", JSON.stringify(cartItems3));
};

export const sudahdibayar = () => (dispatch, getState) => {
  const hasil = [];
  dispatch({
    type: REMOVE_FROM_CART,
    payload: { keranjangState: hasil },
  });

  let cartItems5 = getState().generalState.cartModal;
  cartItems5.totalBelanja = 0;
  cartItems5.modalPembayaran = false;

  dispatch({
    type: CART_MODAL,
    payload: cartItems5,
  });

  //belet
  let te = {
    tgl_kirim: new Date(),
    cttn: "",
  };
  updateCustomer2(te);
};

export const removeFromCart = (product) => (dispatch, getState) => {
  const aksi_getData = async () => {
    const keranjangBlonjo = getState()
      .generalState.keranjangBlonjo.slice()
      .filter((x) => x.kd1 !== product.kd1);

    return keranjangBlonjo;
  };

  const aksi1 = async () => {
    const hasil = await aksi_getData();
    console.log("awan", hasil.length);
    console.log("awan2", hasil);

    var json_arr = JSON.stringify(hasil);
    const formData = new FormData();
    formData.append("data2", json_arr);
    formData.append("banyak", hasil.length);

    axios.post(Urlku + "/simpan-data-belanja", formData, axiosConfig2).then(
      (response) => {
        let te = JSON.stringify(response);
        let te2 = JSON.parse(te);
        //==============================
        let gros = 0;
        let disc = 0;
        let ppn = 0;
        let totalBelanja = 0;
        for (const key of Object.keys(hasil)) {
          var dt = hasil[key];

          gros = 0;
          disc = 0;
          ppn = 0;
          if (dt.qty !== undefined && dt.price !== undefined) {
            gros = dt.qty * dt.price;
          }
          if (dt.persen_disc !== undefined) {
            disc = (gros * dt.persen_disc) / 100;
          }
          if (gros !== 0) {
            ppn = ((gros - disc) * 10) / 100;
          }
          totalBelanja = totalBelanja + (gros - disc + ppn);
        }

        //==================================
        if (te2.data.hasil === 1) {
          dispatch({
            type: REMOVE_FROM_CART,
            payload: { keranjangState: hasil },
          });

          let cartItems5 = getState().generalState.cartModal;
          cartItems5.totalBelanja = totalBelanja;

          dispatch({
            type: CART_MODAL,
            payload: cartItems5,
          });
        } else {
          alert("Proses gagal!");
        }
      },
      (error) => {
        let te = JSON.stringify(error);
        alert("eror: " + te);
        //alert(error)
        console.log(error);
      }
    );
  };

  aksi1();
};
