import React, { Component } from "react";
import { Route,withRouter, Switch } 
from "react-router-dom";
import store from "./store";
import { theRoot } from "./Actions/generalActions";
import "./scss/style.scss";
import i18n from "./i18n";
import 'antd/dist/antdeko.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheLayout = React.lazy(() => import("./containers/TheLayout"));

const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Login3 = React.lazy(() => import("./components/Login2"));
const LoginDirect = React.lazy(() => import("./components/LoginDirect"));


//const Login4 = React.lazy(() => import("./components/Login2b"));

const EWarranty2 = React.lazy(() => import("./eWarranty/Ewarranty2"));

class App extends Component {
  /*
  static propTypes = {
    //const history = this.props.history;
    location: PropTypes.object.isRequired,
  };
*/
  constructor(props) {
    super(props);
    this.state = {
      buka: 1,
      sidebarShow: "responsive",
      sidebarBlonjo: false,
    };
  }

  componentDidMount() {
    let te = localStorage.getItem("dts.language");
    if (te !== undefined && te !== null) {
      i18n.changeLanguage(te);
    }

    te=localStorage.getItem("masukkelas")
    //console.log('gelo',te)
    if (te !== undefined && te !== null) {
      var now = new Date();
      var lalu = new Date(te);

      var diff = (now.getTime() - lalu.getTime()) / 1000;
      diff /= 60;
      let beda = Math.abs(Math.round(diff));
      //beda=5000;
      //lebih 3hari
      if (beda > 1000) {
        localStorage.setItem("iniadmin",'0');
        localStorage.setItem("sidebar1",'0');
        localStorage.setItem("dtswil", '');
        localStorage.removeItem("masukkelas");
        localStorage.removeItem("hakakses");
        localStorage.removeItem("gambarku");

        localStorage.removeItem("bigStore2.user");
        localStorage.removeItem("bigStore2.jwt");
        store.dispatch(theRoot({
          nama: 'ini_admin',
          nilai: false
        }));
        //Koneksiku.pesan("Anda harus login kembali!");
        this.props.history.push("/halaman-utama");   
           
      }
    }
    

    let te1=localStorage.getItem("gambarku");
    console.log('biba',te1)
    if(te1!== null && te1 !== undefined){
      store.dispatch(theRoot({
        nama: 'gambarku',
        nilai: localStorage.getItem("gambarku").trim()
      }));
    }

    te1=localStorage.getItem("bigStore2.user");    
    if(te1!== null && te1 !== undefined){
      let te1a = JSON.parse(te1);
      //console.log('gas1',te1a);
      //console.log('gas2',te1a.fullname);
      store.dispatch(theRoot({
        nama: 'ini_admin',
        nilai: te1a.is_admin
      }));

      store.dispatch(theRoot({
        nama: 'namaku',
        nilai: te1a.fullname.trim()
      }));
    }


  }

  render() {
    //const loggedIn=true;
    return (
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login2"
                name="Login Page"
                render={(props) => <Login3 {...props} />}
              />

              <Route
                exact
                //path="/login-direct/:user/:password"
                path="/login-direct/:user"
                name="Login Direct"
                render={(props) => <LoginDirect {...props} />}
              />

              <Route
                exact
                path="/daftarwarranty/:serial"
                name="EWarranty2"
                render={(props) => <EWarranty2 {...props} />}
                //render={(props) => <TheLayout {...props} />}
              />
            
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />   
              {/* <Route
                path="/halaman-utama"
                name="Home"
                //render={(props) => <Layout2 {...props} />}                
                render={(props) => <TheLayout {...props} />}                
              />            */}
              <Route
                path="/"
                name="Home"
                //render={(props) => <Layout2 {...props} />}                
                render={(props) => <TheLayout {...props} />}                
              /> 
            </Switch>
          </React.Suspense>
    );
  }
}

export default withRouter(App);
//export default App;

