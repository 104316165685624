import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "../react-confirm-alert.css"; // Import css

//const API_URL = "http://localhost:8080/api/auth/";

const url = process.env.REACT_APP_LOKASI_URL;

const axiosConfig = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("bigStore2.jwt"),
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
};

class Koneksiku {
  tanya(pesan) {
    return new Promise((resolve, reject) => {
      confirmAlert({
        message: pesan,
        buttons: [
          {
            label: "Yes",
            onClick: () => resolve(1),
          },
          {
            label: "No",
            onClick: () => resolve(0),
          },
        ],
      });
    });
  }

  pesan(pesan) {
    return new Promise((resolve, reject) => {
      confirmAlert({
        message: pesan,
        buttons: [
          {
            label: "Close",
            onClick: () => resolve(0),
          },
        ],
      });
    });
  }

  dynamicSort(property) {
    return function (obj1, obj2) {
      return obj1[property] > obj2[property]
        ? 1
        : obj1[property] < obj2[property]
        ? -1
        : 0;
    };
  }

  dynamicSortMultiple() {
    /*
     * save the arguments object as it will be overwritten
     * note that arguments object is an array-like object
     * consisting of the names of the properties to sort by
     */
    var props = arguments;
    return function (obj1, obj2) {
      var i = 0,
        result = 0,
        numberOfProperties = props.length;
      /* try getting a different result from 0 (equal)
       * as long as we have extra properties to compare
       */
      while (result === 0 && i < numberOfProperties) {
        result = this.dynamicSort(props[i])(obj1, obj2);
        i++;
      }
      return result;
    };
  }

  getAxio(alamat) {
    return axios.get(url + "/" + alamat, axiosConfig).then((response) => {
      //return response;
      let te = JSON.stringify(response);
      let te2 = JSON.parse(te);
      te = null;
      //console.log(te2.data,"### haddodoooo ##");
      return te2;
    });
  }

  getAxio2(alamat) {
    const dataKota5 = {
      34: "İstanbul",
      63: "Şanlıurfa",
      13: "Jakarta",
      62: "Surabaya",
    };
    return dataKota5;
  }

  dapatAkses(dicari) {
    let k=localStorage.getItem("hakakses");
    if(k!==null && k!==undefined){
      let hakakses = JSON.parse(k);
      //console.log('gagak '+new Date(),hakakses)
      //console.log('gagak '+new Date(),dicari)
      let boleh=false;
      for (let index5 = 0; index5 < hakakses.length; index5++) {
        const el5 = hakakses[index5];
        if(el5.ket1.trim()===dicari){
          boleh=true;
          break;
        }
      }
      return boleh;
    }else{
      return false;
    }    
  }

  postAxio(alamat, param1) {
    return axios
      .get(url + "/" + alamat, param1, axiosConfig)
      .then((response) => {
        let te = JSON.stringify(response);
        let te2 = JSON.parse(te);

        return te2.data.duta;
      });
  }

  getUrl = () => {
    return process.env.REACT_APP_LOKASI_URL;
  };

  getAxiosConfig = () => {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bigStore2.jwt"),
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  //localStorage.getItem('bigStore2.jwt')
  getUser = () => {
    const kas2a = localStorage.getItem("bigStore2.user");
    //let kas2=JSON.parse(kas2a);
    //alert(kas2.tenant);

    //let k=localStorage.getItem('bigStore2.user');
    //let te=JSON.stringify(k);
    //let te2=JSON.parse(k);

    return JSON.parse(kas2a);
  };

  logout() {
    localStorage.removeItem("user");
  }
  /*
  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
  */
}

export default new Koneksiku();
