//import axios from "axios";

import {
  ADD_TO_CART,
  ADD_TO_BLONJO,
  REMOVE_FROM_CART,
  CART_IMAGES,
  CART_DETAIL,
  CART_MODAL,
  INFO_CUSTOMER,
  UPDATE_WAITING,
  UPDATE_PATH,
  RELOAD_PRODUCT,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER2,
  RELOAD_PRODUCT_MORE,
  ISI_LIST_PRODUCT,THE_ROOT
} from "../types";

const initialState2 = {
  sidebarShow: "responsive",
  sidebarBlonjo: false,
};

//state = {generalItems: initialState2},
//state = { generalItems: JSON.parse(localStorage.getItem("generalItems") || "[]") },
export const generalReducer = (
  state = {
    // theSideBar4: 'responsive',
    theSideBar4: false,
    theSideBar: true,
    pesanBaru: 0,
    ini_admin: false,
    namaku: '',
    gambarku: '',
    info_customer: {
      diskon_customer: 0,
      alamat_kirim: "",
    },
    generalWaiting: false,
    generalPath: "",
    filterPaginasiProduct: {
      limit: 100,
      start: 0,
      filter1: "",
      bmaincd: "",
      order_rangking: 0,
      order_harga: 0,
    },

    generalItems: initialState2,
    keranjangBlonjo: [],
    cartDetail: [],
    cartImage: [],
    cartModal: {
      modal1: false,
      gambar: [],
      data: [],
      modalDetailProduct: false,
      modalPembayaran: false,
      price: 0,
      warna: "",
      size: "",
      qty: 0,
      persen_disc: 0,
      neto: 0,
      disc: 0,
      harusDibayar: 0,
      ppn: 0,
      kembalian: 0,
      totalBelanja: 0,
      id_promo: "",
      id_harga: "",
      bonus: 0,
      stok: 0,
    },
    listProduct: [],
    product: [],
    warna1: [],
    size1: [],
    image1: [],
    data_harga: [],
    data_diskon: [],
    data_customer: [],
    data_customer2: {
      dialogEditAlamatKirim: false,
      bukaModal: false,
      customerDialog: false,
      pkd1: "",
      customer: "",
      kdsales: "",
      sales: "",
      wilayah: "",
      alm_kirim: "",
      blocked: false,
      diskon: 0,
      tgl_kirim: new Date(),
      cttn_customer:
        "percobaan percobaan percobaan percobaan percobaan percobaan percobaan percobaan percobaan percobaan percobaan percobaan percobaan percobaan percobaan percobaan  ",
      cttn: "",
      lakukanRender: true,
      nina: false,
      kotakFilter: "",
    },
  },
  action
) => {
  //console.log("sapi4",action.payload.generalState)

  switch (action.type) {
    case RELOAD_PRODUCT:
      return {
        ...state,
        product: action.payload.bdata,
        listProduct: action.payload.bdata,
        warna1: action.payload.warna1,
        size1: action.payload.size,
        image1: action.payload.image1,
        data_harga: action.payload.data_harga,
        data_diskon: action.payload.data_diskon,
        data_customer: action.payload.data_customer,
      };
    case RELOAD_PRODUCT_MORE:
      return {
        ...state,
        product: action.payload.produk,
        listProduct: action.payload.produk,
        warna1: action.payload.warna1,
        size1: action.payload.size1,
        image1: action.payload.image1,
        data_harga: action.payload.data_harga,
        data_diskon: action.payload.data_diskon,
      };

    case ISI_LIST_PRODUCT:
      return { ...state, listProduct: action.payload };
    case UPDATE_CUSTOMER:
      return { ...state, data_customer: action.payload };

    case THE_ROOT:
      return { ...state, [action.payload.nama]: action.payload.nilai };  
    case UPDATE_CUSTOMER2:
      return { ...state, data_customer2: action.payload };
    case INFO_CUSTOMER:
      return { ...state, info_customer: action.payload };
    case CART_IMAGES:
      return { ...state, cartImage: action.payload };
    case CART_DETAIL:
      return { ...state, cartDetail: action.payload };
    case CART_MODAL:
      return { ...state, cartModal: action.payload };
    case ADD_TO_BLONJO:
      return { ...state, keranjangBlonjo: action.payload.keranjangState };
    case ADD_TO_CART:
      return {
        ...state,
        generalItems: action.payload.generalState,
      };
    case UPDATE_PATH:
      return {
        ...state,
        generalPath: action.payload,
      };
    case UPDATE_WAITING:
      return {
        ...state,
        generalWaiting: action.payload,
      };
    case REMOVE_FROM_CART:
      return { ...state, keranjangBlonjo: action.payload.keranjangState };
    default:
      return state;
  }
};
