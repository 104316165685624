import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
//import { productsReducer } from "./Reducers/productReducers";
//import { cartReducer } from "./Reducers/cartReducers";
//import { orderReducer } from "./Reducers/orderReducers";
import { generalReducer } from "./Reducers/generalReducers";

//const initialState = {sidebarShow: "responsive",sidebarBlonjo: false};
const initialState = {};
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  combineReducers({
    generalState: generalReducer
  }),
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
